/* src/Popup.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%; /* Set width to 80% of the screen */
  max-height: 90vh; /* Set maximum height to 90% of the viewport */
  overflow-y: auto; /* Allow vertical scrolling */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-button {
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  float: right;
}
