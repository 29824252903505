.Example__container__document {
    display: flex; /* use flexbox */
    flex-direction: column; /* stack elements vertically */
    justify-content: center; /* center items vertically */
    align-items: center; /* center items horizontally */
    height: 100%; /* ensure the container fills its parent */
}
/* Loading Animation Styles */
.loading-container {
    padding: 1.5em 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-ip {
    width: 8em;
    height: 4em;
}

.loading-ip__track {
    stroke: hsl(223, 90%, 90%);
    transition: stroke 0.3s;
}

.loading-ip__worm1,
.loading-ip__worm2 {
    animation: loading-worm1 2s linear infinite;
}

.loading-ip__worm2 {
    animation-name: loading-worm2;
}

/* Dark theme for loading animation */
@media (prefers-color-scheme: dark) {
    .loading-ip__track {
        stroke: hsl(0, 0%, 100%);
    }
}

/* Animation */
@keyframes loading-worm1 {
    from {
        stroke-dashoffset: 0;
    }
    50% {
        animation-timing-function: steps(1);
        stroke-dashoffset: -358;
    }
    50.01% {
        animation-timing-function: linear;
        stroke-dashoffset: 358;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes loading-worm2 {
    from {
        stroke-dashoffset: 358;
    }
    50% {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: -358;
    }
}
